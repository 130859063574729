import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

// Models
import { metaDescripciones } from '../models/metaDescripciones';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

  constructor(private meta: Meta) { }

  public setMetaTags(tags: metaDescripciones[]): void {
    const finalTags = [];

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].contenidoTag) {
        if(tags[i].nombreTag == "facebook-domain-verification"){
          finalTags.push({ name: tags[i].nombreTag, content: tags[i].contenidoTag });
        }else{
          finalTags.push({ property: tags[i].nombreTag, content: tags[i].contenidoTag });
        }
      } else if (tags[i].contenidoImagen) {
        finalTags.push({ property: tags[i].nombreTag, content: tags[i].contenidoImagen.url });
      }
      //update <meta name="description" content="">
      if(tags[i].nombreTag == "og:description" || tags[i].nombreTag == "description")
        this.meta.updateTag({name: 'description',content: tags[i].contenidoTag});
    }
    this.meta.addTags(finalTags);
  }

  public removeMetaTags(tags: metaDescripciones[]): void {
    for (let i = 0; i < tags.length; i++) {
      this.meta.removeTag(`property="${tags[i].nombreTag}"`);
      if(tags[i].nombreTag == "og:description" || tags[i].nombreTag == "description")
        this.meta.removeTag(`name="description"`);


    }
  }
}
